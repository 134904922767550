import {
    HIDE_MESSAGE,
    SEARCH_AUDIT_TRAIL,
    SEARCH_AUDIT_TRAIL_SUCCESS,
    SEARCH_AUDIT_TRAIL_FAILED,
    SEARCH_AUDIT_TRAIL_MEMBER,
    SEARCH_AUDIT_TRAIL_MEMBER_SUCCESS,
    SEARCH_AUDIT_TRAIL_MEMBER_FAILED,
    FILTER_SEARCH_AUDIT_TRAIL,
    CLEAR_FILTER_SEARCH_AUDIT_TRAIL,
    RESET_STATUS,
    VIEW_AUDIT_TRAIL_SUCCESS,
    VIEW_AUDIT_TRAIL_FAILED,
    GET_LIST_MODULE_NAME,
    GET_LIST_MODULE_NAME_SUCCESS,
    GET_LIST_MODULE_NAME_FAILED
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    auditTrailList: [],
    auditTrailMemberList: [],
    auditTrailView: [],
    moduleNameList: [],
    recordInfo: {
        totalRecords: 0,
        page: 0,
        nrecords: 0
    },
    filterAndSort: {
        pagination: null,
        filters: null,
        sorter: null,
        search: null,
        startDate: null,
        endDate: null,
        typeId: null,
        moduleId: null
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SEARCH_AUDIT_TRAIL:
        case SEARCH_AUDIT_TRAIL_MEMBER:
            return {
                ...state,
                loader: true,
                showMessage: false,
                alertMessage: '',
                auditTrailList: action.type === SEARCH_AUDIT_TRAIL ? [] : state.auditTrailList,
                auditTrailMemberList: action.type === SEARCH_AUDIT_TRAIL_MEMBER ? [] : state.auditTrailMemberList,
                recordInfo: {}
            };

        case SEARCH_AUDIT_TRAIL_SUCCESS:
        case SEARCH_AUDIT_TRAIL_MEMBER_SUCCESS:
            return {
                ...state,
                loader: false,
                auditTrailList: action.type === SEARCH_AUDIT_TRAIL_SUCCESS ? action.payload.auditTrailList : state.auditTrailList,
                auditTrailMemberList: action.type === SEARCH_AUDIT_TRAIL_MEMBER_SUCCESS ? action.payload.auditTrailList : state.auditTrailMemberList,
                recordInfo: action.payload.recordInfo
            };

        case SEARCH_AUDIT_TRAIL_FAILED:
        case SEARCH_AUDIT_TRAIL_MEMBER_FAILED:
            return {
                ...state,
                loader: false,
                alertMessage: action.payload,
                showMessage: true
            };

        case HIDE_MESSAGE:
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            };

        case FILTER_SEARCH_AUDIT_TRAIL:
            return {
                ...state,
                filterAndSort: action.payload
            };

        case CLEAR_FILTER_SEARCH_AUDIT_TRAIL:
            return {
                ...state,
                filterAndSort: {
                    pagination: null,
                    filters: null,
                    sorter: null,
                    search: null,
                    startDate: null,
                    endDate: null,
                    typeId: null,
                    moduleId: null
                }
            };

        case GET_LIST_MODULE_NAME:
            return {
                ...state,
                loader: true,
                showMessage: false,
                alertMessage: '',
                moduleNameList: [],
            };

        case RESET_STATUS:
            return {
                ...state,
            };

        case VIEW_AUDIT_TRAIL_SUCCESS:
            return {
                ...state,
                loader: false,
                auditTrailView: action.payload
            };

        case VIEW_AUDIT_TRAIL_FAILED:
            return {
                ...state,
                loader: false,
                alertMessage: action.payload,
                showMessage: true
            };

        case GET_LIST_MODULE_NAME_SUCCESS:
            return {
                ...state,
                loader: false,
                moduleNameList: action.payload.menu
            };

        case GET_LIST_MODULE_NAME_FAILED:
            return {
                ...state,
                loader: false,
                alertMessage: action.payload,
                showMessage: true
            };

        default:
            return state;
    }
};
