// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//General const
export const RESET_STATUS = 'RESET_STATUS';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const BACK_TO_LOGIN = 'BACK_TO_LOGIN';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILED = 'UPLOAD_IMAGE_FAILED';
export const GET_LIST_COUNTRY = 'GET_LIST_COUNTRY';
export const GET_LIST_COUNTRY_SUCCESS = 'GET_LIST_COUNTRY_SUCCESS';
export const GET_LIST_COUNTRY_FAILED = 'GET_LIST_COUNTRY_FAILED';
export const GET_LIST_PROVINCE = 'GET_LIST_PROVINCE';
export const GET_LIST_PROVINCE_SUCCESS = 'GET_LIST_PROVINCE_SUCCESS';
export const GET_LIST_PROVINCE_FAILED = 'GET_LIST_PROVINCE_FAILED';
export const GET_LIST_CITY = 'GET_LIST_CITY';
export const GET_LIST_CITY_SUCCESS = 'GET_LIST_CITY_SUCCESS';
export const GET_LIST_CITY_FAILED = 'GET_LIST_CITY_FAILED';


//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILED = 'SIGNUP_USER_FAILED';
export const HIDE_MODAL = 'HIDE_MODAL';
export const FILL_EMPTY = 'FILL_EMPTY';

export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_FAILED = 'SIGNIN_USER_FAILED';

export const OTP_USER = 'OTP_USER';
export const OTP_USER_SUCCESS = 'OTP_USER_SUCCESS';
export const OTP_USER_FAILED = 'OTP_USER_FAILED';

export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

export const GET_MERCHANT_AND_ROLE_SEARCH = "GET_MERCHANT_AND_ROLE_SEARCH";

//Rules const
export const SEARCH_RULES = 'SEARCH_RULES';
export const SEARCH_RULES_SUCCESS = 'SEARCH_RULES_SUCCESS';
export const SEARCH_RULES_FAILED = 'SEARCH_RULES_FAILED';
export const FILTER_SEARCH_RULES = 'FILTER_SEARCH_RULES';
export const CLEAR_FILTER_SEARCH_RULES = 'CLEAR_FILTER_SEARCH_RULES';

export const VIEW_RULE = 'VIEW_RULE';
export const VIEW_RULE_SUCCESS = 'VIEW_RULE_SUCCESS';
export const VIEW_RULE_FAILED = 'VIEW_RULE_FAILED';

export const UPDATE_RULE = 'UPDATE_RULE';
export const UPDATE_RULE_SUCCESS = 'UPDATE_RULE_SUCCESS';
export const UPDATE_RULE_FAILED = 'UPDATE_RULE_FAILED';

export const CREATE_RULE = 'CREATE_RULE';
export const CREATE_RULE_SUCCESS = 'CREATE_RULE_SUCCESS';
export const CREATE_RULE_FAILED = 'CREATE_RULE_FAILED';

export const DELETE_RULE = 'DELETE_RULE';
export const DELETE_RULE_SUCCESS = 'DELETE_RULE_SUCCESS';
export const DELETE_RULE_FAILED = 'DELETE_RULE_FAILED';

//Tier const
export const SEARCH_TIERS = 'SEARCH_TIERS';
export const SEARCH_TIERS_SUCCESS = 'SEARCH_TIERS_SUCCESS';
export const SEARCH_TIERS_FAILED = 'SEARCH_TIERS_FAILED';
export const FILTER_SEARCH_TIERS = 'FILTER_SEARCH_TIERS';
export const CLEAR_FILTER_SEARCH_TIERS = 'CLEAR_FILTER_SEARCH_TIERS';

export const VIEW_TIER = 'VIEW_TIER';
export const VIEW_TIER_SUCCESS = 'VIEW_TIER_SUCCESS';
export const VIEW_TIER_FAILED = 'VIEW_TIER_FAILED';

export const UPDATE_TIER = 'UPDATE_TIER';
export const UPDATE_TIER_SUCCESS = 'UPDATE_TIER_SUCCESS';
export const UPDATE_TIER_FAILED = 'UPDATE_TIER_FAILED';

export const CREATE_TIER = 'CREATE_TIER';
export const CREATE_TIER_SUCCESS = 'CREATE_TIER_SUCCESS';
export const CREATE_TIER_FAILED = 'CREATE_TIER_FAILED';

export const DELETE_TIER = 'DELETE_TIER';
export const DELETE_TIER_SUCCESS = 'DELETE_TIER_SUCCESS';
export const DELETE_TIER_FAILED = 'DELETE_TIER_FAILED';

//Program const
export const SEARCH_PROGRAMS = 'SEARCH_PROGRAMS';
export const SEARCH_PROGRAMS_SUCCESS = 'SEARCH_PROGRAMS_SUCCESS';
export const SEARCH_PROGRAMS_FAILED = 'SEARCH_PROGRAMS_FAILED';
export const FILTER_SEARCH_PROGRAMS = 'FILTER_SEARCH_PROGRAMS';
export const CLEAR_FILTER_SEARCH_PROGRAMS = 'CLEAR_FILTER_SEARCH_PROGRAMS';

export const VIEW_PROGRAM = 'VIEW_PROGRAM';
export const VIEW_PROGRAM_SUCCESS = 'VIEW_PROGRAM_SUCCESS';
export const VIEW_PROGRAM_FAILED = 'VIEW_PROGRAM_FAILED';

export const UPDATE_PROGRAM = 'UPDATE_PROGRAM';
export const UPDATE_PROGRAM_SUCCESS = 'UPDATE_PROGRAM_SUCCESS';
export const UPDATE_PROGRAM_FAILED = 'UPDATE_PROGRAM_FAILED';

export const CREATE_PROGRAM = 'CREATE_PROGRAM';
export const CREATE_PROGRAM_SUCCESS = 'CREATE_PROGRAM_SUCCESS';
export const CREATE_PROGRAM_FAILED = 'CREATE_PROGRAM_FAILED';

export const DELETE_PROGRAM = 'DELETE_PROGRAM';
export const DELETE_PROGRAM_SUCCESS = 'DELETE_PROGRAM_SUCCESS';
export const DELETE_PROGRAM_FAILED = 'DELETE_PROGRAM_FAILED';

export const TIER_DETAILS = 'TIER_DETAILS';
export const TIER_DETAILS_SUCCESS = 'TIER_DETAILS_SUCCESS';
export const TIER_DETAILS_FAILED = 'TIER_DETAILS_FAILED';

export const GET_TIER_AND_PROGRAM = 'GET_TIER_AND_PROGRAM'


//Product const
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS';
export const SEARCH_PRODUCTS_FAILED = 'SEARCH_PRODUCTS_FAILED';
export const FILTER_SEARCH_PRODUCTS = 'FILTER_SEARCH_PRODUCTS';
export const CLEAR_FILTER_SEARCH_PRODUCTS = 'CLEAR_FILTER_SEARCH_PRODUCTS';

export const VIEW_PRODUCT = 'VIEW_PRODUCT';
export const VIEW_PRODUCT_SUCCESS = 'VIEW_PRODUCT_SUCCESS';
export const VIEW_PRODUCT_FAILED = 'VIEW_PRODUCT_FAILED';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED';

export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';

export const ADD_STOCK = "ADD_STOCK";
export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const CLEAR_SELECT_PRODUCT = 'CLEAR_SELECT_PRODUCT';


//Simply Blast const
export const SEARCH_BLAST = 'SEARCH_BLAST';
export const SEARCH_BLAST_SUCCESS = 'SEARCH_BLAST_SUCCESS';
export const SEARCH_BLAST_FAILED = 'SEARCH_BLAST_FAILED';
export const FILTER_SEARCH_BLAST = 'FILTER_SEARCH_BLAST';
export const CLEAR_FILTER_SEARCH_BLAST = 'CLEAR_FILTER_SEARCH_BLAST';

export const VIEW_BLAST = 'VIEW_BLAST';
export const VIEW_BLAST_SUCCESS = 'VIEW_BLAST_SUCCESS';
export const VIEW_BLAST_FAILED = 'VIEW_BLAST_FAILED';

export const UPDATE_BLAST = 'UPDATE_BLAST';
export const UPDATE_BLAST_SUCCESS = 'UPDATE_BLAST_SUCCESS';
export const UPDATE_BLAST_FAILED = 'UPDATE_BLAST_FAILED';

export const CREATE_BLAST = 'CREATE_BLAST';
export const CREATE_BLAST_SUCCESS = 'CREATE_BLAST_SUCCESS';
export const CREATE_BLAST_FAILED = 'CREATE_BLAST_FAILED';

export const DELETE_BLAST = 'DELETE_BLAST';
export const DELETE_BLAST_SUCCESS = 'DELETE_BLAST_SUCCESS';
export const DELETE_BLAST_FAILED = 'DELETE_BLAST_FAILED';

export const SELECT_BLAST = 'SELECT_BLAST';
export const CLEAR_SELECT_BLAST = 'CLEAR_SELECT_BLAST';

//Point Value const
export const SEARCH_POINT = 'SEARCH_POINT';
export const SEARCH_POINT_SUCCESS = 'SEARCH_POINT_SUCCESS';
export const SEARCH_POINT_FAILED = 'SEARCH_POINT_FAILED';
export const FILTER_SEARCH_POINT = 'FILTER_SEARCH_POINT';
export const CLEAR_FILTER_SEARCH_POINT = 'CLEAR_FILTER_SEARCH_POINT';

export const VIEW_POINT = 'VIEW_POINT';
export const VIEW_POINT_SUCCESS = 'VIEW_POINT_SUCCESS';
export const VIEW_POINT_FAILED = 'VIEW_POINT_FAILED';

export const UPDATE_POINT = 'UPDATE_POINT';
export const UPDATE_POINT_SUCCESS = 'UPDATE_POINT_SUCCESS';
export const UPDATE_POINT_FAILED = 'UPDATE_POINT_FAILED';

export const CREATE_POINT = 'CREATE_POINT';
export const CREATE_POINT_SUCCESS = 'CREATE_POINT_SUCCESS';
export const CREATE_POINT_FAILED = 'CREATE_POINT_FAILED';

export const DELETE_POINT = 'DELETE_POINT';
export const DELETE_POINT_SUCCESS = 'DELETE_POINT_SUCCESS';
export const DELETE_POINT_FAILED = 'DELETE_POINT_FAILED';


//Promotion const
export const SEARCH_PROMOTIONS = 'SEARCH_PROMOTIONS';
export const SEARCH_PROMOTIONS_SUCCESS = 'SEARCH_PROMOTIONS_SUCCESS';
export const SEARCH_PROMOTIONS_FAILED = 'SEARCH_PROMOTIONS_FAILED';
export const FILTER_SEARCH_PROMOTIONS = 'FILTER_SEARCH_PROMOTIONS';
export const CLEAR_FILTER_SEARCH_PROMOTIONS = 'CLEAR_FILTER_SEARCH_PROMOTIONS';

export const VIEW_PROMOTION = 'VIEW_PROMOTION';
export const VIEW_PROMOTION_SUCCESS = 'VIEW_PROMOTION_SUCCESS';
export const VIEW_PROMOTION_FAILED = 'VIEW_PROMOTION_FAILED';

export const UPDATE_PROMOTION = 'UPDATE_PROMOTION';
export const UPDATE_PROMOTION_SUCCESS = 'UPDATE_PROMOTION_SUCCESS';
export const UPDATE_PROMOTION_FAILED = 'UPDATE_PROMOTION_FAILED';

export const CREATE_PROMOTION = 'CREATE_PROMOTION';
export const CREATE_PROMOTION_SUCCESS = 'CREATE_PROMOTION_SUCCESS';
export const CREATE_PROMOTION_FAILED = 'CREATE_PROMOTION_FAILED';

export const DELETE_PROMOTION = 'DELETE_PROMOTION';
export const DELETE_PROMOTION_SUCCESS = 'DELETE_PROMOTION_SUCCESS';
export const DELETE_PROMOTION_FAILED = 'DELETE_PROMOTION_FAILED';

export const GET_PROMOTION_TIER_RULE = 'GET_PROMOTION_TIER_RULE';

//Member const
export const SEARCH_MEMBERS = 'SEARCH_MEMBERS';
export const SEARCH_MEMBERS_SUCCESS = 'SEARCH_MEMBERS_SUCCESS';
export const SEARCH_MEMBERS_FAILED = 'SEARCH_MEMBERS_FAILED';
export const FILTER_SEARCH_MEMBERS = 'FILTER_SEARCH_MEMBERS';
export const CLEAR_FILTER_SEARCH_MEMBERS = 'CLEAR_FILTER_SEARCH_MEMBERS';

export const UPLOAD_MEMBER = 'UPLOAD_MEMBER';
export const UPLOAD_MEMBER_SUCCESS = 'UPLOAD_MEMBER_SUCCESS';
export const UPLOAD_MEMBER_FAILED = 'UPLOAD_MEMBER_FAILED';

export const VIEW_MEMBER = 'VIEW_MEMBER';
export const VIEW_MEMBER_SUCCESS = 'VIEW_MEMBER_SUCCESS';
export const VIEW_MEMBER_FAILED = 'VIEW_MEMBER_FAILED';

export const CHANGE_STATUS_MEMBER = 'CHANGE_STATUS_MEMBER';
export const CHANGE_STATUS_MEMBER_SUCCESS = 'CHANGE_STATUS_MEMBER_SUCCESS';
export const CHANGE_STATUS_MEMBER_FAILED = 'CHANGE_STATUS_MEMBER_FAILED';

export const UPLOAD_TRANSACTION = 'UPLOAD_TRANSACTION';
export const UPLOAD_TRANSACTION_SUCCESS = 'UPLOAD_TRANSACTION_SUCCESS';
export const UPLOAD_TRANSACTION_FAILED = 'UPLOAD_TRANSACTION_FAILED';

export const APPROVAL_MEMBER = 'APPROVAL_MEMBER';
export const APPROVAL_MEMBER_SUCCESS = 'APPROVAL_MEMBER_SUCCESS';
export const APPROVAL_MEMBER_FAILED = 'APPROVAL_MEMBER_FAILED';

//Roles const
export const SEARCH_ROLES = 'SEARCH_ROLES';
export const SEARCH_ROLES_SUCCESS = 'SEARCH_ROLES_SUCCESS';
export const SEARCH_ROLES_FAILED = 'SEARCH_ROLES_FAILED';
export const FILTER_SEARCH_ROLES = 'FILTER_SEARCH_ROLES';
export const CLEAR_FILTER_SEARCH_ROLES = 'CLEAR_FILTER_SEARCH_ROLES';

export const CREATE_ROLES = 'CREATE_ROLES';
export const CREATE_ROLES_SUCCESS = 'CREATE_ROLES_SUCCESS';
export const CREATE_ROLES_FAILED = 'CREATE_ROLES_FAILED';

export const UPDATE_ROLES = 'UPDATE_ROLES';
export const UPDATE_ROLES_SUCCESS = 'UPDATE_ROLES_SUCCESS';
export const UPDATE_ROLES_FAILED = 'UPDATE_ROLES_FAILED';

export const DELETE_ROLES = 'DELETE_ROLES';
export const DELETE_ROLES_SUCCESS = 'DELETE_ROLES_SUCCESS';
export const DELETE_ROLES_FAILED = 'DELETE_ROLES_FAILED';

export const VIEW_ROLES = 'VIEW_ROLES';
export const VIEW_ROLES_SUCCESS = 'VIEW_ROLES_SUCCESS';
export const VIEW_ROLES_FAILED = 'VIEW_ROLES_FAILED';

export const GET_LIST_PRIVILEGES = 'GET_PRIVILEGES';
export const GET_LIST_PRIVILEGES_SUCCESS = 'GET_PRIVILEGES_SUCCESS';
export const GET_LIST_PRIVILEGES_FAILED = 'GET_PRIVILEGES_FAILED';

export const GET_ROLES_PRIVILEGES = 'GET_ROLES_PRIVILEGES';


//User const
export const SEARCH_USERS = 'SEARCH_USERS';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAILED = 'SEARCH_USERS_FAILED';
export const FILTER_SEARCH_USERS = 'FILTER_SEARCH_USERS';
export const CLEAR_FILTER_SEARCH_USERS = 'CLEAR_FILTER_SEARCH_USERS';

export const CREATE_USERS = 'CREATE_USERS';
export const CREATE_USERS_SUCCESS = 'CREATE_USERS_SUCCESS';
export const CREATE_USERS_FAILED = 'CREATE_USERS_FAILED';

export const UPDATE_USERS = 'UPDATE_USERS';
export const UPDATE_USERS_SUCCESS = 'UPDATE_USERS_SUCCESS';
export const UPDATE_USERS_FAILED = 'UPDATE_USERS_FAILED';

export const DELETE_USERS = 'DELETE_USERS';
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS';
export const DELETE_USERS_FAILED = 'DELETE_USERS_FAILED';

export const VIEW_USERS = 'VIEW_USERS';
export const VIEW_USERS_SUCCESS = 'VIEW_USERS_SUCCESS';
export const VIEW_USERS_FAILED = 'VIEW_USERS_FAILED';

export const CHANGE_STATUS_USER = 'CHANGE_STATUS_USER';
export const CHANGE_STATUS_USER_SUCCESS = 'CHANGE_STATUS_USER_SUCCESS';
export const CHANGE_STATUS_USER_FAILED = 'CHANGE_STATUS_USER_FAILED';

export const CHANGE_PASSWORD_USER = 'CHANGE_PASSWORD_USER';
export const CHANGE_PASSWORD_USER_SUCCESS = 'CHANGE_PASSWORD_USER_SUCCESS';
export const CHANGE_PASSWORD_USER_FAILED = 'CHANGE_PASSWORD_USER_FAILED';

//Advertising const
export const SEARCH_ADVERTISING = 'SEARCH_ADVERTISING';
export const SEARCH_ADVERTISING_SUCCESS = 'SEARCH_ADVERTISING_SUCCESS';
export const SEARCH_ADVERTISING_FAILED = 'SEARCH_ADVERTISING_FAILED';
export const FILTER_SEARCH_ADVERTISING = 'FILTER_SEARCH_ADVERTISING';
export const CLEAR_FILTER_SEARCH_ADVERTISING = 'CLEAR_FILTER_SEARCH_ADVERTISING';

export const CREATE_ADVERTISING = 'CREATE_ADVERTISING';
export const CREATE_ADVERTISING_SUCCESS = 'CREATE_ADVERTISING_SUCCESS';
export const CREATE_ADVERTISING_FAILED = 'CREATE_ADVERTISING_FAILED';

export const UPDATE_ADVERTISING = 'UPDATE_ADVERTISING';
export const UPDATE_ADVERTISING_SUCCESS = 'UPDATE_ADVERTISING_SUCCESS';
export const UPDATE_ADVERTISING_FAILED = 'UPDATE_ADVERTISING_FAILED';

export const DELETE_ADVERTISING = 'DELETE_ADVERTISING';
export const DELETE_ADVERTISING_SUCCESS = 'DELETE_ADVERTISING_SUCCESS';
export const DELETE_ADVERTISING_FAILED = 'DELETE_ADVERTISING_FAILED';

export const VIEW_ADVERTISING = 'VIEW_ADVERTISING';
export const VIEW_ADVERTISING_SUCCESS = 'VIEW_ADVERTISING_SUCCESS';
export const VIEW_ADVERTISING_FAILED = 'VIEW_ADVERTISING_FAILED';

export const TOP_DEALS_ADVERTISING = 'TOP_DEALS_ADVERTISING';
export const TOP_DEALS_ADVERTISING_SUCCESS = 'TOP_DEALS_ADVERTISING_SUCCESS';
export const TOP_DEALS_ADVERTISING_FAILED = 'TOP_DEALS_ADVERTISING_FAILED';

export const SEARCH_ADVERTISING_ACTIVE = 'SEARCH_ADVERTISING_ACTIVE';
export const SEARCH_ADVERTISING_ACTIVE_SUCCESS = 'SEARCH_ADVERTISING_ACTIVE_SUCCESS';
export const SEARCH_ADVERTISING_ACTIVE_FAILED = 'SEARCH_ADVERTISING_ACTIVE_FAILED';

export const GET_TIER_AND_PROGRAM_SEARCH = 'GET_TIER_AND_PROGRAM_SEARCH';
export const FILTER_SEARCH_ADVERTISING_TOPDEALS = "FILTER_SEARCH_ADVERTISING_TOPDEALS";

//Post const
export const SEARCH_POST = 'SEARCH_POST';
export const SEARCH_POST_SUCCESS = 'SEARCH_POST_SUCCESS';
export const SEARCH_POST_FAILED = 'SEARCH_POST_FAILED';
export const FILTER_SEARCH_POST = 'FILTER_SEARCH_POST';
export const CLEAR_FILTER_SEARCH_POST = 'CLEAR_FILTER_SEARCH_POST';

export const VIEW_POST = 'VIEW_POST';
export const VIEW_POST_SUCCESS = 'VIEW_POST_SUCCESS';
export const VIEW_POST_FAILED = 'VIEW_POST_FAILED';

export const UPDATE_POST = 'UPDATE_POST';
export const UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS';
export const UPDATE_POST_FAILED = 'UPDATE_POST_FAILED';

export const CREATE_POST = 'CREATE_POST';
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const CREATE_POST_FAILED = 'CREATE_POST_FAILED';

export const DELETE_POST = 'DELETE_POST';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const DELETE_POST_FAILED = 'DELETE_POST_FAILED';

//Billing const
export const SEARCH_BILLING = 'SEARCH_BILLING';
export const SEARCH_BILLING_SUCCESS = 'SEARCH_BILLING_SUCCESS';
export const SEARCH_BILLING_FAILED = 'SEARCH_BILLING_FAILED';
export const FILTER_SEARCH_BILLING = 'FILTER_SEARCH_BILLING';
export const CLEAR_FILTER_SEARCH_BILLING = 'CLEAR_FILTER_SEARCH_BILLING';

export const VIEW_BILLING = 'VIEW_BILLING';
export const VIEW_BILLING_SUCCESS = 'VIEW_BILLING_SUCCESS';
export const VIEW_BILLING_FAILED = 'VIEW_BILLING_FAILED';

export const BILLING_DETAILS = 'BILLING_DETAILS';
export const BILLING_DETAILS_SUCCESS = 'BILLING_DETAILS_SUCCESS';
export const BILLING_DETAILS_FAILED = 'BILLING_DETAILS_FAILED';

//Merchant const
export const REGISTER_MERCHANT = 'REGISTER_MERCHANT';
export const REGISTER_MERCHANT_SUCCESS = 'REGISTER_MERCHANT_SUCCESS';
export const REGISTER_MERCHANT_FAILED = 'REGISTER_MERCHANT_FAILED';

export const VIEW_MERCHANT = 'VIEW_MERCHANT';
export const VIEW_MERCHANT_SUCCESS = 'VIEW_MERCHANT_SUCCESS';
export const VIEW_MERCHANT_FAILED = 'VIEW_MERCHANT_FAILED';

export const SEARCH_SUB_MERCHANT = 'SEARCH_SUB_MERCHANT';
export const SEARCH_SUB_MERCHANT_SUCCESS = 'SEARCH_SUB_MERCHANT_SUCCESS';
export const SEARCH_SUB_MERCHANT_FAILED = 'SEARCH_SUB_MERCHANT_FAILED';

export const UPDATE_MERCHANT = 'UPDATE_MERCHANT';
export const UPDATE_MERCHANT_SUCCESS = 'UPDATE_MERCHANT_SUCCESS';
export const UPDATE_MERCHANT_FAILED = 'UPDATE_MERCHANT_FAILED';

export const VERIFICATION_MERCHANT = 'VERIFICATION_MERCHANT';
export const VERIFICATION_MERCHANT_SUCCESS = 'VERIFICATION_MERCHANT_SUCCESS';
export const VERIFICATION_MERCHANT_FAILED = 'VERIFICATION_MERCHANT_FAILED';

export const VERIFICATION_FORGOT_MERCHANT = 'VERIFICATION_FORGOT_MERCHANT';
export const VERIFICATION_FORGOT_MERCHANT_SUCCESS = 'VERIFICATION_FORGOT_MERCHANT_SUCCSESS';
export const VERIFICATION_FORGOT_MERCHANT_FAILED = 'VERIFICATION_FORGOT_MERCHANT_FAILED';

export const VERIFICATION_FORGOT_PASSWORD_MERCHANT = 'VERIFICATION_FORGOT_PASSWORD_MERCHANT';
export const VERIFICATION_FORGOT_PASSWORD_MERCHANT_SUCCESS = 'VERIFICATION_FORGOT_PASSWORD_MERCHANT_SUCCSESS';
export const VERIFICATION_FORGOT_PASSWORD_MERCHANT_FAILED = 'VERIFICATION_FORGOT_PASSWORD_MERCHANT_FAILED';

export const ACTIVATE_SANDBOX = 'ACTIVATE_SANDBOX';
export const ACTIVATE_SANDBOX_SUCCESS = 'ACTIVATE_SANDBOX_SUCCESS';
export const ACTIVATE_SANDBOX_FAILED = 'ACTIVATE_SANDBOX_FAILED';

export const GET_CURRENCY = 'GET_CUREENCY';
export const GET_CURRENCY_SUCCESS = 'GET_CURRENCY_SUCCESS';
export const GET_CURRENCY_FAILED = 'GET_CURRENCY_FAILED';

export const GET_LIST_CURRENCY = 'GET_LIST_CUREENCY';
export const GET_LIST_CURRENCY_SUCCESS = 'GET_LIST_CURRENCY_SUCCESS';
export const GET_LIST_CURRENCY_FAILED = 'GET_LIST_CURRENCY_FAILED';

export const CURRENCY_MERCHANT = 'CURRENCY_MERCHANT';
export const CURRENCY_MERCHANT_SUCCESS = 'CURRENCY_MERCHANT_SUCCESS';
export const CURRENCY_MERCHANT_FAILED = 'CURRENCY_MERCHANT_FAILED';

export const SEARCH_MERCHANT = 'SEARCH_MERCHANT';
export const SEARCH_MERCHANT_SUCCESS = 'SEARCH_MERCHANT_SUCCESS';
export const SEARCH_MERCHANT_FAILED = 'SEARCH_MERCHANT_FAILED';


//Report const
export const FILTER_SEARCH_REPORT = 'FILTER_SEARCH_REPORT';
export const CLEAR_FILTER_SEARCH_REPORT = 'CLEAR_FILTER_SEARCH_REPORT';

export const GET_ISSUING_REPORT = 'GET_ISSUING_REPORT';
export const GET_ISSUING_REPORT_SUCCESS = 'GET_ISSUING_REPORT_SUCCESS';
export const GET_ISSUING_REPORT_FAILED = 'GET_ISSUING_REPORT_FAILED';

export const GET_REDEEM_REPORT = 'GET_REDEEM_REPORT';
export const GET_REDEEM_REPORT_SUCCESS = 'GET_REDEEM_REPORT_SUCCESS';
export const GET_REDEEM_REPORT_FAILED = 'GET_REDEEM_REPORT_FAILED';

export const GET_VOUCHER_BALANCE_REPORT = 'GET_VOUCHER_BALANCE_REPORT';
export const GET_VOUCHER_BALANCE_REPORT_SUCCESS = 'GET_VOUCHER_BALANCE_REPORT_SUCCESS';
export const GET_VOUCHER_BALANCE_REPORT_FAILED = 'GET_VOUCHER_BALANCE_REPORT_FAILED';

export const GET_MEMBER_BALANCE_REPORT = 'GET_MEMBER_BALANCE_REPORT';
export const GET_MEMBER_BALANCE_REPORT_SUCCESS = 'GET_MEMBER_BALANCE_REPORT_SUCCESS';
export const GET_MEMBER_BALANCE_REPORT_FAILED = 'GET_MEMBER_BALANCE_REPORT_FAILED';

export const GET_REFERRAL_REPORT = 'GET_REFERRAL_REPORT';
export const GET_REFERRAL_REPORT_SUCCESS = 'GET_REFERRAL_REPORT_SUCCESS';
export const GET_REFERRAL_REPORT_FAILED = 'GET_REFERRAL_REPORT_FAILED';

export const GET_POINT_TRANSACTION_REPORT = 'GET_POINT_TRANSACTION_REPORT';
export const GET_POINT_TRANSACTION_REPORT_SUCCESS = 'GET_POINT_TRANSACTION_REPORT_SUCCESS';
export const GET_POINT_TRANSACTION_REPORT_FAILED = 'GET_POINT_TRANSACTION_REPORT_FAILED';

export const GET_POINT_TRANSFER_REPORT = 'GET_POINT_TRANSFER_REPORT';
export const GET_POINT_TRANSFER_REPORT_SUCCESS = 'GET_POINT_TRANSFER_REPORT_SUCCESS';
export const GET_POINT_TRANSFER_REPORT_FAILED = 'GET_POINT_TRANSFER_REPORT_FAILED';

export const GET_PARTNER_REPORT = 'GET_PARTNER_REPORT';
export const GET_PARTNER_REPORT_SUCCESS = 'GET_PARTNER_REPORT_SUCCESS';
export const GET_PARTNER_REPORT_FAILED = 'GET_PARTNER_REPORT_FAILED';

export const GET_REDEEM_PARTNER_REPORT = 'GET_REDEEM_PARTNER_REPORT';
export const GET_REDEEM_PARTNER_REPORT_SUCCESS = 'GET_REDEEM_PARTNER_REPORT_SUCCESS';
export const GET_REDEEM_PARTNER_REPORT_FAILED = 'GET_REDEEM_PARTNER_REPORT_FAILED';

export const GET_GIFTAWAY_VOUCHER_REPORT = 'GET_GIFTAWAY_VOUCHER_REPORT';
export const GET_GIFTAWAY_VOUCHER_REPORT_SUCCESS = 'GET_GIFTAWAY_VOUCHER_REPORT_SUCCESS';
export const GET_GIFTAWAY_VOUCHER_REPORT_FAILED = 'GET_GIFTAWAY_VOUCHER_REPORT_FAILED';

export const UPLOAD_TRANSACTION_APPROVAL_REPORT = 'UPLOAD_TRANSACTION_APPROVAL_REPORT';
export const UPLOAD_TRANSACTION_APPROVAL_REPORT_SUCCESS = 'UPLOAD_TRANSACTION_APPROVAL_REPORT_SUCCESS';
export const UPLOAD_TRANSACTION_APPROVAL_REPORT_FAILED = 'UPLOAD_TRANSACTION_APPROVAL_REPORT_FAILED';

export const GET_LOYALTI_POINT_ACTIVITY = 'GET_LOYALTI_POINT_ACTIVITY';
export const GET_LOYALTI_POINT_ACTIVITY_SUCCESS = 'GET_LOYALTI_POINT_ACTIVITY_SUCCESS';
export const GET_LOYALTI_POINT_ACTIVITY_FAILED = 'GET_LOYALTI_POINT_ACTIVITY_FAILED';

export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS';


//Dashboard const
export const GET_LOYALTY_PROMOTION_DATA = 'GET_LOYALTY_PROMOTION_DATA';
export const GET_LOYALTY_PROMOTION_DATA_SUCCESS = 'GET_LOYALTY_PROMOTION_DATA_SUCCESS';
export const GET_LOYALTY_PROMOTION_DATA_FAILED = 'GET_LOYALTY_PROMOTION_DATA_FAILED';

export const GET_LOYALTY_REWARD_DATA = 'GET_LOYALTY_REWARD_DATA';
export const GET_LOYALTY_REWARD_DATA_SUCCESS = 'GET_LOYALTY_REWARD_DATA_SUCCESS';
export const GET_LOYALTY_REWARD_DATA_FAILED = 'GET_LOYALTY_REWARD_DATA_FAILED';

export const GET_LOYALTY_TOTAL_DATA = 'GET_LOYALTY_TOTAL_DATA';
export const GET_LOYALTY_TOTAL_DATA_SUCCESS = 'GET_LOYALTY_TOTAL_DATA_SUCCESS';
export const GET_LOYALTY_TOTAL_DATA_FAILED = 'GET_LOYALTY_TOTAL_DATA_FAILED';

export const GET_MARKETPLACE_MERCHANT_DATA = 'GET_MARKETPLACE_MERCHANT_DATA';
export const GET_MARKETPLACE_MERCHANT_DATA_SUCCESS = 'GET_MARKETPLACE_MERCHANT_DATA_SUCCESS';
export const GET_MARKETPLACE_MERCHANT_DATA_FAILED = 'GET_MARKETPLACE_MERCHANT_DATA_FAILED';

export const GET_MARKETPLACE_PRODUCT_DATA = 'GET_MARKETPLACE_PRODUCT_DATA';
export const GET_MARKETPLACE_PRODUCT_DATA_SUCCESS = 'GET_MARKETPLACE_PRODUCT_DATA_SUCCESS';
export const GET_MARKETPLACE_PRODUCT_DATA_FAILED = 'GET_MARKETPLACE_PRODUCT_DATA_FAILED';

export const GET_MARKETPLACE_REVENUE_DATA = 'GET_MARKETPLACE_REVENUE_DATA';
export const GET_MARKETPLACE_REVENUE_DATA_SUCCESS = 'GET_MARKETPLACE_REVENUE_DATA_SUCCESS';
export const GET_MARKETPLACE_REVENUE_DATA_FAILED = 'GET_MARKETPLACE_REVENUE_DATA_FAILED';

export const GET_MEMBER_GROWTH_DATA = 'GET_MEMBER_GROWTH_DATA';
export const GET_MEMBER_GROWTH_DATA_SUCCESS = 'GET_MEMBER_GROWTH_DATA_SUCCESS';
export const GET_MEMBER_GROWTH_DATA_FAILED = 'GET_MEMBER_GROWTH_DATA_FAILED';

export const GET_MEMBER_SUMMARY_DATA = 'GET_MEMBER_SUMMARY_DATA';
export const GET_MEMBER_SUMMARY_DATA_SUCCESS = 'GET_MEMBER_SUMMARY_DATA_SUCCESS';
export const GET_MEMBER_SUMMARY_DATA_FAILED = 'GET_MEMBER_SUMMARY_DATA_FAILED';

export const GET_AVALIABLE_POINT_DATA = 'GET_AVALIABLE_POINT_DATA';
export const GET_AVALIABLE_POINT_DATA_SUCCESS = 'GET_AVALIABLE_POINT_DATA_SUCCESS';
export const GET_AVALIABLE_POINT_DATA_FAILED = 'GET_AVALIABLE_POINT_DATA_FAILED';

export const GET_DEDUCT_POINT_DATA = 'GET_DEDUCT_POINT_DATA';
export const GET_DEDUCT_POINT_DATA_SUCCESS = 'GET_DEDUCT_POINT_DATA_SUCCESS';
export const GET_DEDUCT_POINT_DATA_FAILED = 'GET_DEDUCT_POINT_DATA_FAILED';


export const GET_TIER_MEMBER_DATA = 'GET_TIER_MEMBER_DATA';
export const GET_TIER_MEMBER_DATA_SUCCESS = 'GET_TIER_MEMBER_DATA_SUCCESS';
export const GET_TIER_MEMBER_DATA_FAILED = 'GET_TIER_MEMBER_DATA_FAILED';

//Menu const
export const GET_MENU_MERCHANT_DATA = 'GET_MENU_MERCHANT_DATA';
export const GET_MENU_MERCHANT_DATA_SUCCESS = 'GET_MENU_MERCHANT_DATA_SUCCESS';
export const GET_MENU_MERCHANT_DATA_FAILED = 'GET_MENU_MERCHANT_DATA_FAILED';

//Doorprize const
export const SEARCH_DOORPRIZE = 'SEARCH_DOORPRIZE';
export const SEARCH_DOORPRIZE_SUCCESS = 'SEARCH_DOORPRIZE_SUCCESS';
export const SEARCH_DOORPRIZE_FAILED = 'SEARCH_DOORPRIZE_FAILED';
export const FILTER_SEARCH_DOORPRIZE = 'FILTER_SEARCH_DOORPRIZE';
export const CLEAR_FILTER_SEARCH_DOORPRIZE = 'CLEAR_FILTER_SEARCH_DOORPRIZE';

export const CREATE_DOORPRIZE = 'CREATE_DOORPRIZE';
export const CREATE_DOORPRIZE_SUCCESS = 'CREATE_DOORPRIZE_SUCCESS';
export const CREATE_DOORPRIZE_FAILED = 'CREATE_DOORPRIZE_FAILED';

// Deposit const
export const GET_DEPOSIT_BALANCE_HISTORY = 'GET_DEPOSIT_BALANCE_HISTORY';
export const GET_DEPOSIT_BALANCE_HISTORY_SUCCESS = 'GET_DEPOSIT_BALANCE_HISTORY_SUCCESS';
export const GET_DEPOSIT_BALANCE_HISTORY_FAILED = 'GET_DEPOSIT_BALANCE_HISTORY_FAILED';
export const GET_VA_LIST = 'GET_VA_LIST';
export const GET_VA_LIST_SUCCESS = 'GET_VA_LIST_SUCCESS';
export const GET_VA_LIST_FAILED = 'GET_VA_LIST_FAILED';
export const GET_BALANCE = 'GET_BALANCE';
export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS';
export const GET_BALANCE_FAILED = 'GET_BALANCE_FAILED';

// Gamification const
export const SEARCH_GAMIFICATION = 'SEARCH_GAMIFICATION';
export const SEARCH_GAMIFICATION_SUCCESS = 'SEARCH_GAMIFICATION_SUCCESS';
export const SEARCH_GAMIFICATION_FAILED = 'SEARCH_GAMIFICATION_FAILED';
export const FILTER_SEARCH_GAMIFICATION = 'FILTER_SEARCH_GAMIFICATION';
export const CLEAR_FILTER_SEARCH_GAMIFICATION = 'CLEAR_FILTER_SEARCH_GAMIFICATION';

export const VIEW_GAMIFICATION = 'VIEW_GAMIFICATION';
export const VIEW_GAMIFICATION_SUCCESS = 'VIEW_GAMIFICATION_SUCCESS';
export const VIEW_GAMIFICATION_FAILED = 'VIEW_GAMIFICATION_FAILED';

export const UPDATE_GAMIFICATION = 'UPDATE_GAMIFICATION';
export const UPDATE_GAMIFICATION_SUCCESS = 'UPDATE_GAMIFICATION_SUCCESS';
export const UPDATE_GAMIFICATION_FAILED = 'UPDATE_GAMIFICATION_FAILED';

export const CREATE_GAMIFICATION = 'CREATE_GAMIFICATION';
export const CREATE_GAMIFICATION_SUCCESS = 'CREATE_GAMIFICATION_SUCCESS';
export const CREATE_GAMIFICATION_FAILED = 'CREATE_GAMIFICATION_FAILED';

export const DELETE_GAMIFICATION = 'DELETE_GAMIFICATION';
export const DELETE_GAMIFICATION_SUCCESS = 'DELETE_GAMIFICATION_SUCCESS';
export const DELETE_GAMIFICATION_FAILED = 'DELETE_GAMIFICATION_FAILED';

export const GET_GAMIFICATION_TYPES = 'GET_GAMIFICATION_TYPES';
export const GET_GAMIFICATION_TYPES_SUCCESS = 'GET_GAMIFICATION_TYPES_SUCCESS';
export const GET_GAMIFICATION_TYPES_FAILED = 'GET_GAMIFICATION_TYPES_FAILED';

export const CREATE_GAMIFICATION_QUIZ = 'CREATE_GAMIFICATION_QUIZ';
export const CREATE_GAMIFICATION_QUIZ_SUCCESS = 'CREATE_GAMIFICATION_QUIZ_SUCCESS';
export const CREATE_GAMIFICATION_QUIZ_FAILED = 'CREATE_GAMIFICATION_QUIZ_FAILED';
export const CREATE_GAMIFICATION_SURVEY = 'CREATE_GAMIFICATION_SURVEY';
export const CREATE_GAMIFICATION_SURVEY_SUCCESS = 'CREATE_GAMIFICATION_SURVEY_SUCCESS';
export const CREATE_GAMIFICATION_SURVEY_FAILED = 'CREATE_GAMIFICATION_SURVEY_FAILED';
export const CREATE_GAMIFICATION_SPINNER = 'CREATE_GAMIFICATION_SPINNER';
export const CREATE_GAMIFICATION_SPINNER_SUCCESS = 'CREATE_GAMIFICATION_SURVEY_SPINNER';
export const CREATE_GAMIFICATION_SPINNER_FAILED = 'CREATE_GAMIFICATION_SURVEY_SPINNER';

export const UPDATE_GAMIFICATION_QUIZ = 'UPDATE_GAMIFICATION_QUIZ';
export const UPDATE_GAMIFICATION_QUIZ_SUCCESS = 'UPDATE_GAMIFICATION_QUIZ_SUCCESS';
export const UPDATE_GAMIFICATION_QUIZ_FAILED = 'UPDATE_GAMIFICATION_QUIZ_FAILED';
export const UPDATE_GAMIFICATION_SURVEY = 'UPDATE_GAMIFICATION_SURVEY';
export const UPDATE_GAMIFICATION_SURVEY_SUCCESS = 'UPDATE_GAMIFICATION_SURVEY_SUCCESS';
export const UPDATE_GAMIFICATION_SURVEY_FAILED = 'UPDATE_GAMIFICATION_SURVEY_FAILED';
export const UPDATE_GAMIFICATION_SPINNER = 'UPDATE_GAMIFICATION_SPINNER';
export const UPDATE_GAMIFICATION_SPINNER_SUCCESS = 'UPDATE_GAMIFICATION_SURVEY_SPINNER';
export const UPDATE_GAMIFICATION_SPINNER_FAILED = 'UPDATE_GAMIFICATION_SURVEY_SPINNER';

//Approval const
export const SEARCH_APPROVAL = 'SEARCH_APPROVAL';
export const SEARCH_APPROVAL_SUCCESS = 'SEARCH_APPROVAL_SUCCESS';
export const SEARCH_APPROVAL_FAILED = 'SEARCH_APPROVAL_FAILED';
export const FILTER_SEARCH_APPROVAL = 'FILTER_SEARCH_APPROVAL';
export const CLEAR_FILTER_SEARCH_APPROVAL = 'CLEAR_FILTER_SEARCH_APPROVAL';

export const VIEW_APPROVAL = 'VIEW_APPROVAL';
export const VIEW_APPROVAL_SUCCESS = 'VIEW_APPROVAL_SUCCESS';
export const VIEW_APPROVAL_FAILED = 'VIEW_APPROVAL_FAILED';

export const UPDATE_APPROVAL = 'UPDATE_APPROVAL';
export const UPDATE_APPROVAL_SUCCESS = 'UPDATE_APPROVAL_SUCCESS';
export const UPDATE_APPROVAL_FAILED = 'UPDATE_APPROVAL_FAILED';

export const INQ_POST_APPROVAL = 'INQ_POST_APPROVAL';
export const INQ_POST_APPROVAL_SUCCESS = 'INQ_POST_APPROVAL_SUCCESS';
export const INQ_POST_APPROVAL_FAILED = 'INQ_POST_APPROVAL_FAILED';

export const UPLOAD_TRANSACTION_POST_APPROVAL = 'UPLOAD_TRANSACTION_POST_APPROVAL';
export const UPLOAD_TRANSACTION_POST_APPROVAL_SUCCESS = 'UPLOAD_TRANSACTION_POST_APPROVAL_SUCCESS';
export const UPLOAD_TRANSACTION_POST_APPROVAL_FAILED = 'UPLOAD_TRANSACTION_POST_APPROVAL_FAILED';

export const APPROVE_REJECT_APPROVAL = 'APPROVE_REJECT_APPROVAL';
export const APPROVE_REJECT_APPROVAL_SUCCESS = 'APPROVE_REJECT_APPROVAL_SUCCESS';
export const APPROVE_REJECT_APPROVAL_FAILED = 'APPROVE_REJECT_APPROVAL_FAILED';


//Reconciliation const
export const SEARCH_RECONCILIATION = 'SEARCH_RECONCILIATION';
export const SEARCH_RECONCILIATION_SUCCESS = 'SEARCH_RECONCILIATION_SUCCESS';
export const SEARCH_RECONCILIATION_FAILED = 'SEARCH_RECONCILIATION_FAILED';
export const FILTER_SEARCH_RECONCILIATION = 'FILTER_SEARCH_RECONCILIATION';
export const FILTER_SEARCH_RECONCILIATION_PAYABLE = 'FILTER_SEARCH_RECONCILIATION_PAYABLE';
export const CLEAR_FILTER_SEARCH_RECONCILIATION = 'CLEAR_FILTER_SEARCH_RECONCILIATION';

export const SEARCH_RECONCILIATION_PAYABLE = 'SEARCH_RECONCILIATION_PAYABLE';
export const SEARCH_RECONCILIATION_PAYABLE_SUCCESS = 'SEARCH_RECONCILIATION_PAYABLE_SUCCESS';
export const SEARCH_RECONCILIATION_PAYABLE_FAILED = 'SEARCH_RECONCILIATION_PAYABLE_FAILED';

export const SEARCH_RECONCILIATION_RECEIVEBLE = 'SEARCH_RECONCILIATION_RECEIVEBLE';
export const SEARCH_RECONCILIATION_RECEIVEBLE_SUCCESS = 'SEARCH_RECONCILIATION_RECEIVEBLE_SUCCESS';
export const SEARCH_RECONCILIATION_RECEIVEBLE_FAILED = 'SEARCH_RECONCILIATION_RECEIVEBLE_FAILED';

export const SEARCH_RECONCILIATION_POINTFEE = 'SEARCH_RECONCILIATION_POINTFEE';
export const SEARCH_RECONCILIATION_POINTFEE_SUCCESS = 'SEARCH_RECONCILIATION_POINTFEE_SUCCESS';
export const SEARCH_RECONCILIATION_POINTFEE_FAILED = 'SEARCH_RECONCILIATION_POINTFEE_FAILED';

export const VIEW_RECONCILIATION = 'VIEW_RECONCILIATION';
export const VIEW_RECONCILIATION_SUCCESS = 'VIEW_RECONCILIATION_SUCCESS';
export const VIEW_RECONCILIATION_FAILED = 'VIEW_RECONCILIATION_FAILED';

export const RECONCILIATION_DETAILS = 'RECONCILIATION_DETAILS';
export const RECONCILIATION_DETAILS_SUCCESS = 'RECONCILIATION_DETAILS_SUCCESS';
export const RECONCILIATION_DETAILS_FAILED = 'RECONCILIATION_DETAILS_FAILED';

//Store const

export const SEARCH_STORE = 'SEARCH_STORE';
export const SEARCH_STORE_SUCCESS = 'SEARCH_STORE_SUCCESS';
export const SEARCH_STORE_FAILED = 'SEARCH_STORE_FAILED';
export const FILTER_SEARCH_STORE = 'FILTER_SEARCH_STORE';
export const CLEAR_FILTER_SEARCH_STORE = 'CLEAR_FILTER_SEARCH_STORE';

export const VIEW_STORE = 'VIEW_STORE';
export const VIEW_STORE_SUCCESS = 'VIEW_STORE_SUCCESS';
export const VIEW_STORE_FAILED = 'VIEW_STORE_FAILED';

export const CREATE_STORE = 'CREATE_STORE';
export const CREATE_STORE_SUCCESS = 'CREATE_STORE_SUCCESS';
export const CREATE_STORE_FAILED = 'CREATE_STORE_FAILED';

export const UPDATE_STORE = 'UPDATE_STORE';
export const UPDATE_STORE_SUCCESS = 'UPDATE_STORE_SUCCESS';
export const UPDATE_STORE_FAILED = 'UPDATE_STORE_FAILED';

export const DELETE_STORE = 'DELETE_STORE';
export const DELETE_STORE_SUCCESS = 'DELETE_STORE_SUCCESS';
export const DELETE_STORE_FAILED = 'DELETE_STORE_FAILED';

//Audit Trail const

export const SEARCH_AUDIT_TRAIL = 'SEARCH_AUDIT_TRAIL';
export const SEARCH_AUDIT_TRAIL_SUCCESS = 'SEARCH_AUDIT_TRAIL_SUCCESS';
export const SEARCH_AUDIT_TRAIL_FAILED = 'SEARCH_AUDIT_TRAIL_FAILED';
export const SEARCH_AUDIT_TRAIL_MEMBER = 'SEARCH_AUDIT_TRAIL_MEMBER';
export const SEARCH_AUDIT_TRAIL_MEMBER_SUCCESS = 'SEARCH_AUDIT_TRAIL_MEMBER_SUCCESS';
export const SEARCH_AUDIT_TRAIL_MEMBER_FAILED = 'SEARCH_AUDIT_TRAIL_MEMBER_FAILED';
export const FILTER_SEARCH_AUDIT_TRAIL = 'FILTER_SEARCH_AUDIT_TRAIL';
export const CLEAR_FILTER_SEARCH_AUDIT_TRAIL = 'CLEAR_FILTER_SEARCH_AUDIT_TRAIL';

export const VIEW_AUDIT_TRAIL = 'VIEW_AUDIT_TRAIL';
export const VIEW_AUDIT_TRAIL_SUCCESS = 'VIEW_AUDIT_TRAIL_SUCCESS';
export const VIEW_AUDIT_TRAIL_FAILED = 'VIEW_AUDIT_TRAIL_FAILED';

export const GET_LIST_MODULE_NAME = 'GET_LIST_MODULE_NAME';
export const GET_LIST_MODULE_NAME_SUCCESS = 'GET_LIST_MODULE_NAME_SUCCESS';
export const GET_LIST_MODULE_NAME_FAILED = 'GET_LIST_MODULE_NAME_FAILED';

//Category const

export const FILTER_SEARCH_CATEGORY = 'FILTER_SEARCH_CATEGORY';
export const CLEAR_FILTER_SEARCH_CATEGORY = 'CLEAR_FILTER_SEARCH_CATEGORY';

export const VIEW_CATEGORY = 'VIEW_CATEGORY';
export const VIEW_CATEGORY_SUCCESS = 'VIEW_CATEGORY_SUCCESS';
export const VIEW_CATEGORY_FAILED = 'VIEW_CATEGORY_FAILED';

//Validation Voucher const

export const UPDATE_VALIDATION_VOUCHER = 'UPDATE_VALIDATION_VOUCHER';
export const UPDATE_VALIDATION_VOUCHER_SUCCESS = 'UPDATE_VALIDATION_VOUCHER_SUCCESS';
export const UPDATE_VALIDATION_VOUCHER_FAILED = 'UPDATE_VALIDATION_VOUCHER_FAILED';

// Builder const
export const GET_BUILDER_DATA = 'GET_BUILDER_DATA';
export const GET_BUILDER_DATA_SUCCESS = 'GET_BUILDER_DATA_SUCCESS';
export const GET_BUILDER_DATA_FAILED = 'GET_BUILDER_DATA_FAILED';

export const VIEW_BUILDER_DATA = 'VIEW_BUILDER_DATA';
export const VIEW_BUILDER_DATA_SUCCESS = 'VIEW_BUILDER_DATA_SUCCESS';
export const VIEW_BUILDER_DATA_FAILED = 'VIEW_BUILDER_DATA_FAILED';

export const CREATE_BUILDER_DATA = 'CREATE_BUILDER_DATA';
export const CREATE_BUILDER_DATA_SUCCESS = 'CREATE_BUILDER_DATA_SUCCESS';
export const CREATE_BUILDER_DATA_FAILED = 'CREATE_BUILDER_DATA_FAILED';

export const UPDATE_BUILDER_DATA = 'UPDATE_BUILDER_DATA';
export const UPDATE_BUILDER_DATA_SUCCESS = 'UPDATE_BUILDER_DATA_SUCCESS';
export const UPDATE_BUILDER_DATA_FAILED = 'UPDATE_BUILDER_DATA_FAILED';
